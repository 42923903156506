import React, { useState, useEffect } from 'react';
import { collection, getDocs } from 'firebase/firestore';
import { FIRESTORE_DB } from '../firebaseConfig';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import '../App.css'; // Certifique-se de importar o arquivo de estilos

const Dashboard = () => {
  const [games, setGames] = useState([]);
  const [averageDurationWon, setAverageDurationWon] = useState(0);
  const [averageDurationLost, setAverageDurationLost] = useState(0);
  const [averageWordsFound, setAverageWordsFound] = useState(0);
  const [averageGameTime, setAverageGameTime] = useState(0);
  const [totalGames, setTotalGames] = useState(0);
  const [wonGamesCount, setWonGamesCount] = useState(0);
  const [lostGamesCount, setLostGamesCount] = useState(0);
  const [mostFoundWords, setMostFoundWords] = useState([]);
  const [peakTimes, setPeakTimes] = useState([]);

  useEffect(() => {
    const fetchGames = async () => {
      const querySnapshot = await getDocs(collection(FIRESTORE_DB, 'gameRecords'));
      const gamesList = querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
      setGames(gamesList);

      const wonGames = gamesList.filter(game => game.status === 'won');
      const lostGames = gamesList.filter(game => game.status === 'lost');
      const totalWordsFound = gamesList.reduce((acc, game) => acc + (game.wordsFound ? game.wordsFound.length : 0), 0);
      const totalGameTime = gamesList.reduce((acc, game) => acc + (game.duration ? parseInt(game.duration, 10) : 0), 0);

      setTotalGames(gamesList.length);
      setWonGamesCount(wonGames.length);
      setLostGamesCount(lostGames.length);
      setAverageDurationWon(wonGames.length ? wonGames.reduce((acc, game) => acc + (game.duration ? parseInt(game.duration, 10) : 0), 0) / wonGames.length : 0);
      setAverageDurationLost(lostGames.length ? lostGames.reduce((acc, game) => acc + (game.duration ? parseInt(game.duration, 10) : 0), 0) / lostGames.length : 0);
      setAverageWordsFound(gamesList.length ? totalWordsFound / gamesList.length : 0);
      setAverageGameTime(gamesList.length ? totalGameTime / gamesList.length : 0);

      const wordCount = {};
      const peakTimesCount = {};

      gamesList.forEach(game => {
        if (game.wordsFound) {
          game.wordsFound.forEach(word => {
            if (wordCount[word]) {
              wordCount[word]++;
            } else {
              wordCount[word] = 1;
            }
          });
        }

        if (game.startedAt) {
          const hour = new Date(`1970-01-01T${game.startedAt}Z`).getUTCHours();
          if (peakTimesCount[hour]) {
            peakTimesCount[hour]++;
          } else {
            peakTimesCount[hour] = 1;
          }
        }
      });

      const sortedWords = Object.entries(wordCount).sort((a, b) => b[1] - a[1]);
      setMostFoundWords(sortedWords);

      const peakTimesData = Object.entries(peakTimesCount).map(([hour, count]) => ({
        hour: `${hour}:00`,
        count
      })).sort((a, b) => parseInt(a.hour) - parseInt(b.hour));
      setPeakTimes(peakTimesData);
    };

    fetchGames();
  }, []);

  return (
    <div className="dashboard-container p-8 text-white">
      <h1 className="text-3xl font-bold mb-6">Dashboard</h1>
      <div className="statistics grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 mb-8">
        <div className="stat bg-gray-800 p-4 rounded-lg shadow-md">
          <p className="text-lg font-semibold">Total de Registros</p>
          <p className="text-2xl">{totalGames}</p>
        </div>
        <div className="stat bg-gray-800 p-4 rounded-lg shadow-md">
          <p className="text-lg font-semibold">Registros Ganhos</p>
          <p className="text-2xl">{wonGamesCount}</p>
        </div>
        <div className="stat bg-gray-800 p-4 rounded-lg shadow-md">
          <p className="text-lg font-semibold">Registros Perdidos</p>
          <p className="text-2xl">{lostGamesCount}</p>
        </div>
        <div className="stat bg-gray-800 p-4 rounded-lg shadow-md">
          <p className="text-lg font-semibold">Média de Duração (Ganhos)</p>
          <p className="text-2xl">{averageDurationWon.toFixed(2)} segundos</p>
        </div>
        <div className="stat bg-gray-800 p-4 rounded-lg shadow-md">
          <p className="text-lg font-semibold">Média de Duração (Perdidos)</p>
          <p className="text-2xl">{averageDurationLost.toFixed(2)} segundos</p>
        </div>
        <div className="stat bg-gray-800 p-4 rounded-lg shadow-md">
          <p className="text-lg font-semibold">Média de Palavras Encontradas</p>
          <p className="text-2xl">{averageWordsFound.toFixed(2)}</p>
        </div>
        <div className="stat bg-gray-800 p-4 rounded-lg shadow-md">
          <p className="text-lg font-semibold">Tempo Médio dos Jogos</p>
          <p className="text-2xl">{averageGameTime.toFixed(2)} segundos</p>
        </div>
      </div>
      <div className="overflow-x-auto max-h-96 h-[30vh] mb-8">
        <table className="min-w-full bg-gray-800 rounded-lg overflow-hidden shadow-md">
          <thead>
            <tr className="bg-gray-700">
              <th className="px-4 py-2">Data</th>
              <th className="px-4 py-2">Duração</th>
              <th className="px-4 py-2">Tempo de partida</th>
              <th className="px-4 py-2">Início</th>
              <th className="px-4 py-2">Status</th>
              <th className="px-4 py-2">Palavras Encontradas</th>
            </tr>
          </thead>
          <tbody>
            {games.map((game) => (
              <tr key={game.id} className="border-t border-gray-700">
                <td className="px-4 py-2">{game.StartDate}</td>
                <td className="px-4 py-2">{game.duration} segundos</td>
                <td className="px-4 py-2">{game.elapsedTime}</td>
                <td className="px-4 py-2">{game.startedAt}</td>
                <td className="px-4 py-2">{game.status}</td>
                <td className="px-4 py-2">{game.wordsFound ? game.wordsFound.join(', ') : ''}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="overflow-y-auto max-h-60 mb-8 bg-gray-800 p-4 rounded-lg shadow-md h-[30vh]">
        <h2 className="text-2xl font-bold mb-4 ">Palavras Mais Encontradas</h2>
        <ul>
          {mostFoundWords.map(([word, count]) => (
            <li key={word} className="flex justify-between border-b border-gray-700 py-2">
              <span>{word}</span>
              <span>{count} vezes</span>
            </li>
          ))}
        </ul>
      </div>
      <div className="bg-gray-800 p-4 rounded-lg shadow-md">
        <h2 className="text-2xl font-bold mb-4">Horários de Pico</h2>
        <ResponsiveContainer width="100%" height={300}>
          <LineChart data={peakTimes}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="hour" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Line type="monotone" dataKey="count" stroke="#8884d8" activeDot={{ r: 8 }} />
          </LineChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

export default Dashboard;
