import React from 'react';
import { motion } from 'framer-motion';

const Cell = ({ row, col, letter, onMouseDown, onMouseEnter, onTouchStart, isSelected, isFound }) => {
  return (
    <motion.td
      className={`cell w-10 h-10 m-8 text-center align-middle text-xl cursor-pointer font-bold
        ${isFound ? 'bg-kluber-yellow' : isSelected ? 'bg-yellow-500' : 'bg-white'} text-black rounded-md`}
      onMouseDown={onMouseDown}
      onMouseEnter={onMouseEnter}
      onTouchStart={onTouchStart}
      data-row={row}
      data-col={col}
      style={{ userSelect: 'none' }} 
    >
      {letter}
    </motion.td>
  );
};


export default Cell;
