import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import LandingPage from './LandingPage';
import Board from './components/Board';
import Dashboard from './components/Dashboard';

const App = () => {
  return (
    <Router>
      <div className="App h-screen text-white flex flex-col items-center justify-center">
        <Routes>
          <Route path="/" element={<Board />} />
          <Route path="/dashboard" element={<Dashboard />} />
        </Routes>
      </div>
    </Router>
  );
};

export default App;
