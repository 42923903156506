import { useState, useEffect } from 'react';
import { collection, addDoc, updateDoc, doc } from 'firebase/firestore';
import { FIRESTORE_DB } from '../firebaseConfig';
import { formatTime } from '../utils';

const useGameState = (initialState, totalTime, wordList, indexLetters) => {
  const [gameState, setGameState] = useState(initialState);
  const [selectedCells, setSelectedCells] = useState([]);
  const [foundWords, setFoundWords] = useState([]);
  const [foundCells, setFoundCells] = useState([]);
  const [startTime, setStartTime] = useState(null);
  const [gameRecordId, setGameRecordId] = useState(null);
  const [message, setMessage] = useState('');
  const [isDragging, setIsDragging] = useState(false);
  const [isGameEnded, setIsGameEnded] = useState(false);

  const createGameRecord = async () => {
    try {
      const now = new Date();
      const docRef = await addDoc(collection(FIRESTORE_DB, 'gameRecords'), {
        StartDate: now.toLocaleDateString('pt-BR'),
        startedAt: now.toLocaleTimeString('pt-BR'),
      });
      setGameRecordId(docRef.id);
    } catch (e) {
      console.error('Error adding document: ', e);
    }
  };

  const updateGameRecord = async (status, wordsFound, duration) => {
    if (!gameRecordId || isGameEnded) return;

    const gameRecordRef = doc(FIRESTORE_DB, 'gameRecords', gameRecordId);

    await updateDoc(gameRecordRef, {
      status,
      wordsFound,
      duration, 
      elapsedTime: formatTime(duration), 
    });

    setIsGameEnded(true); 
  };

  return {
    gameState,
    setGameState,
    selectedCells,
    setSelectedCells,
    foundWords,
    setFoundWords,
    foundCells,
    setFoundCells,
    startTime,
    setStartTime,
    gameRecordId,
    setGameRecordId,
    message,
    setMessage,
    isDragging,
    setIsDragging,
    createGameRecord,
    updateGameRecord,
  };
};

export default useGameState;
