const wordList = [
	["KOSHER", "HALAL", "NSF", "FOODGRADE", "KLUBER"],
	[
		"EFICIÊNCIA",
		"CONFIÁVEL",
		"SUSTENTÁVEL",
		"ECONOMIA",
		"HIGIENE",
	],
	["SEGURANÇA", "HACCP", "SOLUÇÕES", "ENERGIA", "PERFORMANCE"],
	["KLUBERGREASE", "KLUBERFOOD", "REFRIGERAÇÃO", "PARCERIA", "CLIENTE"],
];

export default wordList;
