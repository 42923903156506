import React, { useEffect, useMemo, useState, useRef } from 'react';
import { motion } from 'framer-motion';
import Cell from './Cell';
import Clock from '../assets/clock.png';
import KluberLogo from '../assets/logo.png';
import QRCodeImage from '../assets/qrcode.png';
import QRCodeImageSite from '../assets/qrcodeSite.jpg';
import wordList from '../data/wordList';
import useGameState from '../hooks/useGameState';
import { generateBoard, formatTime, fillBoardWithWords } from '../utils';

const Board = () => {
  const size = 15;
  const totalTime = 90; // 1min e 30 segundos
  const [currentIndex, setCurrentIndex] = useState(Number(localStorage.getItem('currentIndex')) || 0);
  const [gameKey, setGameKey] = useState(0); // para forçar a recriação do componente

  useEffect(() => {
    localStorage.setItem('currentIndex', currentIndex);
  }, [currentIndex]);

  const indexLetters = useMemo(() => currentIndex % wordList.length, [currentIndex]);
  const board = useMemo(() => fillBoardWithWords(generateBoard(size), wordList[indexLetters]), [indexLetters]);

  const {
    gameState,
    setGameState,
    selectedCells,
    setSelectedCells,
    foundWords,
    setFoundWords,
    foundCells,
    setFoundCells,
    startTime,
    setStartTime,
    gameRecordId,
    setGameRecordId,
    message,
    setMessage,
    isDragging,
    setIsDragging,
    createGameRecord,
    updateGameRecord,
  } = useGameState('notStarted', totalTime, wordList, indexLetters);

  const [elapsedTime, setElapsedTime] = useState(totalTime);
  const [countdown, setCountdown] = useState(5);
  const [isCountdownActive, setIsCountdownActive] = useState(false);
  const [hasCreatedRecord, setHasCreatedRecord] = useState(false);
  const [roundDuration, setRoundDuration] = useState(0);
  const [isGameEnded, setIsGameEnded] = useState(false);

  const timerRef = useRef(null);
  const countdownRef = useRef(null);

  useEffect(() => {
    if (selectedCells.length > 0) {
      const formedWord = selectedCells.map((cell) => cell.letter).join('');
      if (
        wordList[indexLetters].includes(formedWord) &&
        formedWord.length === selectedCells.length
      ) {
        if (!foundWords.includes(formedWord)) {
          setTimeout(() => {
            setFoundWords((prev) => [...prev, formedWord]);
            setFoundCells((prev) => [...prev, ...selectedCells]);
            setMessage(`Você encontrou a palavra: ${formedWord}`);
            setTimeout(() => setMessage(''), 3000);
            setSelectedCells([]);
          }, 100);
        }
      } else if (!wordList[indexLetters].some((word) => word.startsWith(formedWord))) {
        setSelectedCells([]);
      }
    }
  }, [selectedCells, foundWords, indexLetters]);

  useEffect(() => {
    if (foundWords.length === wordList[indexLetters].length && !isGameEnded) {
      const gameDuration = Math.floor((Date.now() - startTime) / 1000);
      setGameState('won');
      setRoundDuration(gameDuration);
      setMessage(`Parabéns! Você encontrou todas as palavras em ${formatTime(gameDuration)}.`);
      cancelAnimationFrame(timerRef.current); // Para o cronômetro
      setIsGameEnded(true);
      updateGameRecord('won', foundWords, gameDuration);
    }
  }, [foundWords, indexLetters, startTime, updateGameRecord, isGameEnded]);

  useEffect(() => {
    if (gameState === 'playing') {
      const updateTimer = () => {
        const timePassed = Math.floor((Date.now() - startTime) / 1000);
        const remainingTime = totalTime - timePassed;

        if (remainingTime <= 0) {
          setGameState('lost');
          setElapsedTime(0);
          setMessage(`Tempo esgotado! Você perdeu.`);
          setIsGameEnded(true);
          updateGameRecord('lost', foundWords, totalTime);
        } else {
          setElapsedTime(remainingTime);
          timerRef.current = requestAnimationFrame(updateTimer);
        }
      };

      timerRef.current = requestAnimationFrame(updateTimer);

      return () => cancelAnimationFrame(timerRef.current);
    } else if (isGameEnded) {
      cancelAnimationFrame(timerRef.current);
    }
  }, [gameState, startTime, totalTime, updateGameRecord, foundWords, isGameEnded]);

  useEffect(() => {
    if (gameState === 'won' || gameState === 'lost') {
      const timeout = setTimeout(() => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % wordList.length);
        setGameKey((prevKey) => prevKey + 1);
        localStorage.setItem('currentIndex', (currentIndex + 1) % wordList.length);
        window.location.reload();
      }, 15000);

      return () => clearTimeout(timeout);
    }
  }, [gameState, currentIndex]);

  const handleCellMouseDown = (row, col) => {
    if (gameState === 'notStarted') {
      startGame();
    }
    if (gameState !== 'playing') return;
    setIsDragging(true);
    handleCellSelection(row, col);
  };

  const handleCellMouseUp = () => {
    setIsDragging(false);
  };

  const handleCellMouseEnter = (row, col) => {
    if (isDragging && gameState === 'playing') {
      handleCellSelection(row, col);
    }
  };

  const handleCellSelection = (row, col) => {
    const isSelected = selectedCells.some((cell) => cell.row === row && cell.col === col);
    const isFound = foundCells.some((cell) => cell.row === row && cell.col === col);

    if (isSelected || isFound) return;

    const newSelectedCells = [...selectedCells, { row, col, letter: board[row][col] }];
    setSelectedCells(newSelectedCells);
  };

  const isSelected = (row, col) => {
    return selectedCells.some((cell) => cell.row === row && cell.col === col);
  };

  const isFound = (row, col) => {
    return foundCells.some((cell) => cell.row === row && cell.col === col);
  };

  const startGame = () => {
    if (!hasCreatedRecord) {
      setIsCountdownActive(true);
      setHasCreatedRecord(true);
      createGameRecord();
      countdownRef.current = setInterval(() => {
        setCountdown((prevCountdown) => {
          if (prevCountdown === 1) {
            clearInterval(countdownRef.current);
            setGameState('playing');
            setStartTime(Date.now());
            setIsCountdownActive(false);
          }
          return prevCountdown - 1;
        });
      }, 1000);
    }
  };

  useEffect(() => {
    return () => clearInterval(countdownRef.current);
  }, []);


  return (
    <div key={gameKey} className="flex p-4 text-white gap-5">
      <div className="relative w-3/4 p-4 bg-black">
        {gameState === 'notStarted' && (
          <div className="absolute inset-0 flex items-center justify-center z-10">
            <button
              onClick={startGame}
              className="px-4 py-2 bg-kluber-yellow text-black font-bold rounded"
            >
              Começar Jogo
            </button>
          </div>
        )}
        <motion.table
          className={`mx-auto border-collapse ${gameState === 'notStarted' || isCountdownActive ? 'blur-md' : ''}`}
          cellSpacing="10"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          onMouseUp={handleCellMouseUp}
        >
          <tbody>
            {board.map((row, rowIndex) => (
              <tr key={rowIndex}>
                {row.map((cell, colIndex) => (
                  <Cell
                    key={colIndex}
                    row={rowIndex}
                    col={colIndex}
                    letter={cell}
                    isSelected={isSelected(rowIndex, colIndex)}
                    isFound={isFound(rowIndex, colIndex)}
                    onMouseDown={() => handleCellMouseDown(rowIndex, colIndex)}
                    onMouseEnter={() => handleCellMouseEnter(rowIndex, colIndex)}
                  />
                ))}
              </tr>
            ))}
          </tbody>
        </motion.table>
        {gameState === 'won' && (
          <p className='text-kluber-yellow text-3xl mt-10 font-bold' >Tempo decorrido: {formatTime(roundDuration)}</p>
        )}
        {isCountdownActive && (
          <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50 z-10">
            <div className="text-5xl text-white">{countdown}</div>
          </div>
        )}
      </div>
      <div className="flex flex-col justify-between items-start py-10 px-8 w-[16rem] bg-[#000] shadow-md shadow-kluber-yellow">
        {gameState === 'notStarted' && !isCountdownActive ? (
          <div className="text-start text-lg text-kluber-yellow">
            <p className='mb-5 text-2xl' >Veja tudo de melhor que espera por você aqui.</p>
            <p className='mb-5 text-2xl'>Encontre 5 palavras que fazem parte do mundo Klüber no tempo de 1'30"</p>
          </div>
        ) : gameState === 'playing' || isCountdownActive ? (
          <>
            <div className='w-full flex items-center justify-center'>
              <div className="bg-kluber-yellow w-[10rem] h-[3rem] my-5 flex items-center justify-center">
                <img src={Clock} className="w-10 h-10" alt="clock" />
                <div className="text-3xl text-black font-bold">{formatTime(elapsedTime)}</div>
              </div>
            </div>
            <div>
              <div className='w-full'>
                <ul className="text-lg items-start text-start mr-6">
                  {wordList[indexLetters].map((word, index) => (
                    <li key={index} className={`my-3 ${foundWords.includes(word) ? 'text-kluber-yellow line-through' : ''}`}>
                      {word}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </>
        ) : gameState === 'won' ? (
          <div className="text-start text-lg flex flex-col justify-between h-full">
            <div className='font-bold mt-2'>
              <p className='mb-5' >Parabéns! Você ganhou!</p>
              <p className='mb-2'>Agradecemos por participar e estar com a Klüber nesta jornada.</p>
              <p className='mb-2'>Movemos o futuro com as nossas soluções e continuaremos a avançar.</p>
              <p className="text-kluber-yellow">Juntos, além da lubrificação.</p>
            </div>
            <div className="flex flex-col items-start gap-4 mt-4">
              <div className="flex items-center justify-between w-full">
                <span>Visite nosso Site</span>
                <img src={QRCodeImageSite} alt="QR Code para o site" className="ml-2" style={{ width: '64px', height: '64px' }} />
              </div>
              <div className="flex items-center justify-between w-full">
                <span>Siga nosso LinkedIn</span>
                <img src={QRCodeImage} alt="QR Code para o LinkedIn" className="ml-2" style={{ width: '64px', height: '64px' }} />
              </div>
            </div>
          </div>
        ) : (
          <div className="text-start text-lg flex flex-col justify-between h-full">
            <div>
              <p>Não foi dessa vez.</p>
              <p>Agradecemos por participar e estar com a Klüber nesta jornada.</p>
              <p>Movemos o futuro com as nossas soluções e continuaremos a avançar.</p>
              <p className="text-kluber-yellow">Juntos, além da lubrificação.</p>
            </div>
            <div className="flex flex-col items-start gap-4 mt-4">
              <div className="flex items-center justify-between w-full">
                <span>Visite nosso Site</span>
                <img src={QRCodeImageSite} alt="QR Code para o site" className="ml-2" style={{ width: '64px', height: '64px' }} />
              </div>
              <div className="flex items-center justify-between w-full">
                <span>Siga nosso LinkedIn</span>
                <img src={QRCodeImage} alt="QR Code para o LinkedIn" className="ml-2" style={{ width: '64px', height: '64px' }} />
              </div>
            </div>
          </div>
        )}
        <img src={KluberLogo} className="mt-10" alt="KluberLogo" />
      </div>
      <footer className="fixed bottom-0 right-3 mb-2">
        <p>© Phygital Solutions</p>
      </footer>
    </div>
  );
};

export default Board;
