export const generateBoard = (size) => {
    let board = [];
    for (let i = 0; i < size; i++) {
        let row = [];
        for (let j = 0; j < size; j++) {
            row.push('');
        }
        board.push(row);
    }
    return board;
};

export const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${String(minutes).padStart(2, '0')}:${String(secs).padStart(2, '0')}`;
  };
  
export const fillBoardWithWords = (board, words) => {
    const size = board.length;
    words.forEach((word) => {
        let placed = false;
        let attempts = 0;
        while (!placed && attempts < 1000) {
            const direction = Math.random() > 0.5 ? 'HORIZONTAL' : 'VERTICAL';
            const row = Math.floor(Math.random() * size);
            const col = Math.floor(Math.random() * size);

            if (direction === 'HORIZONTAL' && col + word.length <= size) {
                let canPlace = true;
                for (let i = 0; i < word.length; i++) {
                    if (board[row][col + i] !== '') {
                        canPlace = false;
                        break;
                    }
                }
                if (canPlace) {
                    for (let i = 0; i < word.length; i++) {
                        board[row][col + i] = word[i];
                    }
                    placed = true;
                }
            } else if (direction === 'VERTICAL' && row + word.length <= size) {
                let canPlace = true;
                for (let i = 0; i < word.length; i++) {
                    if (board[row + i][col] !== '') {
                        canPlace = false;
                        break;
                    }
                }
                if (canPlace) {
                    for (let i = 0; i < word.length; i++) {
                        board[row + i][col] = word[i];
                    }
                    placed = true;
                }
            }
            attempts++;
        }
        if (!placed) {
            console.error(`Não foi possível colocar a palavra: ${word}`);
        }
    });

    for (let i = 0; i < size; i++) {
        for (let j = 0; j < size; j++) {
            if (board[i][j] === '') {
                board[i][j] = String.fromCharCode(65 + Math.floor(Math.random() * 26));
            }
        }
    }

    return board;
};


