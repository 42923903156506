import { initializeApp } from "firebase/app";
import { getFirestore } from 'firebase/firestore'

const firebaseConfig = {
    apiKey: "AIzaSyCnY-VjfN39DwTCIy70JOaKVmVXaGCoWdk",
    authDomain: "kluber-cacapalavras.firebaseapp.com",
    projectId: "kluber-cacapalavras",
    storageBucket: "kluber-cacapalavras.appspot.com",
    messagingSenderId: "59237202808",
    appId: "1:59237202808:web:73844be0d0d687533e268d"
};


export const FIREBASE_APP = initializeApp(firebaseConfig)
export const FIRESTORE_DB = getFirestore(FIREBASE_APP)